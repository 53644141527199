<template>
  <Login
    :formFields="formFields"
    :authHeading="$t('Log in as a broker')"
    :cardHeading="$t('Log in as a broker')"
    :loginBtnText="$t('login')"
    registerRouteName="BrokerRegister"
    type="broker"
    lostPasswordLink="/lostPassword/email?type=broker"
  />
</template>

<script>
import Login from "@/components/Auth/Login.vue";
export default {
  components: {
    Login,
  },
  data() {
    return {
      lostPasswordText: "I have lost my password",
      loginBtnText: "Inloggen",
      createAccountText: "Create an account",
      formFields: [
        {
          component: "TextInput",
          name: "email",
          placeholder: this.$t('emailPlaceholder'),
          rules: "email|required",
          label: this.$t("Office email address"),
          colClasses: "pb-0",
          "type":"email"
        },
        {
          component: "PasswordInput",
          name: "password",
          type: "password",
          placeholder: this.$t('password'),
          rules: "required",
          label: this.$t("Password"),
          colClasses: "pt-0"
        },
      ],
    };
  },
  computed:{
    heading(){
      return this.$t('broker.login.heading')
    }
  }
};
</script>

<style lang="scss"></style>
