<template>
  <div class="login" :class="externalClass">
    <AuthLayoutContent>
      <template v-slot:left>
        <div>
          <div class="left">
            <div class="row" v-if="verify_otp">
              <div class="col-md-12">
                <BrokerOTP @otpVerified="otpVerified" />
              </div>
            </div>

            <template v-else>
              <div class="row mb-4">
                <div class="col-md-12">
                  <h1>
                    {{ $t("Log in as a broker") }}
                  </h1>
                  <!-- <p class="text-muted">
              </p> -->
                </div>
              </div>

              <Form
                :formFields="formFields"
                :stacked="true"
                @on-submit="onSubmit"
                :error="getErrors.auth"
              >
                <template v-slot:formfooter>
                  <slot>
                    <div>
                      <div class="text-right mb-3">
                        <routerLink
                          :to="lostPasswordLink"
                          class="text-muted mb-0"
                          v-if="lostPasswordLink"
                        >
                          {{ $t("Forgot your password?") }}
                        </routerLink>
                      </div>

                      <div class="d-flex flex-column align-items-center">
                        <button
                          class="btn btn-primary w-100 mb-3"
                          :disabled="getLoadings.auth"
                        >
                          {{ loginBtnText }}
                          <b-spinner
                            v-if="getLoadings.auth"
                            small
                            label="Spinning"
                            variant="white"
                          ></b-spinner>
                        </button>

                        <routerLink
                          class="btn btn-outline-primary w-100"
                          :to="{ name: 'UserRegister' }"
                          v-if="type == 'user'"
                        >
                          {{ $t("Create an account") }}
                        </routerLink>

                        <routerLink
                          class="createAccount mt-3"
                          :to="{ name: 'BrokerRegister' }"
                          v-if="type == 'broker'"
                        >
                          {{ $t("No account yet?") }}
                          <strong>
                            {{ $t("Register") }}
                          </strong>
                        </routerLink>

                        <!-- <routerLink
                        class="createAccount mt-3"
                        :to="{ name: 'BrokerLogin' }"
                        v-if="type == 'user'"
                      >
                        {{ $t("Are you a broker?") }}
                        <strong>
                          {{ $t("click here") }}
                        </strong>
                      </routerLink> -->
                      </div>
                    </div>
                  </slot>
                </template>
              </Form>
            </template>
          </div>
        </div>
      </template>
      <template v-slot:right>
        <RightSection :img="img" />
      </template>
    </AuthLayoutContent>
  </div>
</template>

<script>
import Form from "@/components/ui/form/Form.vue";
import RightSection from "../common/RightSection.vue";
import AuthLayoutContent from "../common/AuthLayoutContent.vue";
import { mapActions, mapGetters } from "vuex";
import BrokerOTP from "./BrokerOTP.vue";

export default {
  props: [
    "loginBtnText",
    "cardHeading",
    "formFields",
    "authHeading",
    "externalClass",
    "registerRouteName",
    "type",
    "otherUserLink",
    "otherUserText",
    "lostPasswordLink",
  ],
  data() {
    return {
      img: require("../../assets/images/auth/broker_login.svg"),
      verify_otp: false,
    };
  },
  components: {
    Form,
    RightSection,
    AuthLayoutContent,
    BrokerOTP,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters(["getLocale"]),
  },

  methods: {
    ...mapActions("auth", ["auth"]),
    async onSubmit(formData) {
      formData.type = this.type;
      formData.locale = this.getLocale;

      await this.auth({
        body: formData,
        url: "/login",
      });
      this.verify_otp = true;
    },
    async otpVerified() {
      window.location.href = "/broker/listings";
    },
  },
};
</script>

<style lang="scss">
.login {
  height: 100%;

  &__card {
    max-width: 412px;

    @include xs {
      max-width: 90%;
    }
  }

  &__createAccount {
    border: 1px solid #e1e1e1 !important;
    // max-width: 234px !important;
    padding: 14px !important;
    border-radius: 5px !important;
    width: 100%;

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05) !important;

    &__text {
      padding-left: 11px !important;
      @include font(1rem, $dark2, 700);

      @include xs {
        font-size: 0.9rem;
        padding-left: 12px !important;
      }
    }
  }

  &__btn {
    padding: 1rem 1.375rem !important;

    @include xs {
      padding: 1rem 3.875rem !important;
      margin-top: 10px;
    }
  }
}

.createAccount {
  color: #6c727f;
}

.left {
  margin: auto;
  max-width: 500px;
  @include xs {
    margin: 0;
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
